import React, { useState, useEffect, useCallback } from 'react';
import { Palette, Type } from 'lucide-react';
import ReactGA from 'react-ga4';
import styles from './FontCombinationViewer.module.css';

const fontCombos = [
    { 
      display: 'Playfair Display', 
      body: 'Source Sans Pro',
      displayInfo: 'Serif font designed by Claus Eggers Sørensen in 2010',
      bodyInfo: 'Sans-serif font designed by Paul D. Hunt in 2010',
      displayUrl: 'https://fonts.google.com/specimen/Playfair+Display',
      bodyUrl: 'https://fonts.google.com/specimen/Source+Sans+Pro'
    },
    { 
      display: 'Quattrocento', 
      body: 'Quattrocento Sans',
      displayInfo: 'Serif font designed by Pablo Impallari in 2012',
      bodyInfo: 'Sans-serif companion to Quattrocento, also by Pablo Impallari',
      displayUrl: 'https://fonts.google.com/specimen/Quattrocento',
      bodyUrl: 'https://fonts.google.com/specimen/Quattrocento+Sans'
    },
    { 
      display: 'Fjalla One', 
      body: 'Cantarell',
      displayInfo: 'Sans-serif font designed by Sorkin Type in 2011',
      bodyInfo: 'Humanist sans-serif font designed by Dave Crossland in 2009',
      displayUrl: 'https://fonts.google.com/specimen/Fjalla+One',
      bodyUrl: 'https://fonts.google.com/specimen/Cantarell'
    },
    {
      display: 'Plus Jakarta Sans',
      body: 'Roboto',
      displayInfo: 'Geometric sans-serif designed by Gumpita Rahayu in 2020',
      bodyInfo: 'Neo-grotesque sans-serif designed by Christian Robertson in 2011',
      displayUrl: 'https://fonts.google.com/specimen/Plus+Jakarta+Sans',
      bodyUrl: 'https://fonts.google.com/specimen/Roboto'
    },
    {
      display: 'Manrope',
      body: 'Montserrat',
      displayInfo: 'Geometric sans-serif designed by Mikhail Sharanda in 2018',
      bodyInfo: 'Geometric sans-serif inspired by urban typography, designed by Julieta Ulanovsky in 2010',
      displayUrl: 'https://fonts.google.com/specimen/Manrope',
      bodyUrl: 'https://fonts.google.com/specimen/Montserrat'
    },
    {
      display: 'Space Grotesk',
      body: 'Open Sans',
      displayInfo: 'Grotesque sans-serif designed by Florian Karsten in 2020',
      bodyInfo: 'Humanist sans-serif designed by Steve Matteson in 2010',
      displayUrl: 'https://fonts.google.com/specimen/Space+Grotesk',
      bodyUrl: 'https://fonts.google.com/specimen/Open+Sans'
    },
    {
      display: 'Sora',
      body: 'Inter',
      displayInfo: 'Geometric sans-serif designed by Jonathan Barnbrook and Julián Moncada in 2019',
      bodyInfo: 'Sans-serif designed for computer screens by Rasmus Andersson in 2017',
      displayUrl: 'https://fonts.google.com/specimen/Sora',
      bodyUrl: 'https://fonts.google.com/specimen/Inter'
    }
];

const colorSchemes = [
  { name: 'Light', background: '#ffffff', text: '#333333', slider: '#4a4a4a', button: '#4a4a4a', border:'#4a4a4a' },
  { name: 'Dark', background: '#333333', text: '#ffffff', slider: '#b3b3b3', button: '#b3b3b3', border:'#b3b3b3' },
  { name: 'Sepia', background: '#f4ecd8', text: '#5b4636', slider: '#8a7b6d', button: '#8B4513', border:'#8B4513' },
  { name: 'Night', background: '#0f0f23', text: '#00ccff', slider: '#3399cc', button: '#0077be', border:'#0077be' },
  { name: 'Cobalt', background: '#2448FF', text: '#E7F6FF', slider: '#8C9EFA', button: '#b3bfff', border:'#b3bfff' },
  { name: 'Blush', background: '#FDC3C3', text: '#FD4B4B', slider: '#FD4B4B', button: '#FD4B4B', border:'#FD4B4B'  },
];

const DEFAULT_TITLE_FONT_SIZE = 52;
const DEFAULT_BODY_FONT_SIZE = 20;

const FontCombinationViewer = () => {
  const [currentFontIndex, setCurrentFontIndex] = useState(0);
  const [currentColorScheme, setCurrentColorScheme] = useState(0);
  const [isChanging, setIsChanging] = useState(false);
  const [titleFontSize, setTitleFontSize] = useState(DEFAULT_TITLE_FONT_SIZE);
  const [bodyFontSize, setBodyFontSize] = useState(DEFAULT_BODY_FONT_SIZE);
  const [isTabletOrMobile, setIsTabletOrMobile] = useState(window.innerWidth <= 1024);

  const changeFont = useCallback(() => {
    setIsChanging(true);
    setCurrentFontIndex((prevIndex) => (prevIndex + 1) % fontCombos.length);
    ReactGA.event({
      category: 'User Interaction',
      action: 'Changed Font Combination',
      label: `${fontCombos[(currentFontIndex + 1) % fontCombos.length].display} / ${fontCombos[(currentFontIndex + 1) % fontCombos.length].body}`
    });
    setTimeout(() => setIsChanging(false), 300);
  }, [currentFontIndex]);

  const toggleColorScheme = useCallback(() => {
    setCurrentColorScheme((prevScheme) => (prevScheme + 1) % colorSchemes.length);
    ReactGA.event({
      category: 'User Interaction',
      action: 'Changed Color Scheme',
      label: colorSchemes[(currentColorScheme + 1) % colorSchemes.length].name
    });
  }, [currentColorScheme]);

  useEffect(() => {
    const handleResize = () => {
      const newIsTabletOrMobile = window.innerWidth <= 1024;
      setIsTabletOrMobile(newIsTabletOrMobile);
      if (newIsTabletOrMobile) {
        setTitleFontSize(DEFAULT_TITLE_FONT_SIZE);
        setBodyFontSize(DEFAULT_BODY_FONT_SIZE);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.code === 'Space' && !isTabletOrMobile) {
        event.preventDefault();
        changeFont();
      } else if (event.code === 'ArrowLeft' || event.code === 'ArrowRight') {
        event.preventDefault();
        toggleColorScheme();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [changeFont, toggleColorScheme, isTabletOrMobile]);

  useEffect(() => {
    const loadFonts = async () => {
      const { display, body } = fontCombos[currentFontIndex];
      const link = document.createElement('link');
      link.href = `https://fonts.googleapis.com/css2?family=${display.replace(' ', '+')}:wght@400;700&family=${body.replace(' ', '+')}:wght@400;700&display=swap`;
      link.rel = 'stylesheet';
      document.head.appendChild(link);
    };

    loadFonts();
  }, [currentFontIndex]);

  const { display, body, displayInfo, bodyInfo } = fontCombos[currentFontIndex];
  const { background, text, slider, button, border } = colorSchemes[currentColorScheme];

  const handleTitleFontSizeChange = (e) => {
    const newSize = e.target.value;
    setTitleFontSize(newSize);
    ReactGA.event({
      category: 'User Interaction',
      action: 'Changed Title Font Size',
      label: `${newSize}px`
    });
  };

  const handleBodyFontSizeChange = (e) => {
    const newSize = e.target.value;
    setBodyFontSize(newSize);
    ReactGA.event({
      category: 'User Interaction',
      action: 'Changed Body Font Size',
      label: `${newSize}px`
    });
  };

  const getGoogleFontUrl = (fontName) => {
    return `https://fonts.google.com/specimen/${fontName.replace(' ', '+')}`;
  };

  const SpacebarIcon = () => (
    <svg width="20" height="10" viewBox="0 0 20 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className={styles.spacebarIcon}>
      <rect x="1" y="1" width="18" height="8" rx="2" stroke="currentColor" strokeWidth="2" fill="none"/>
    </svg>
  );

  return (
    <div className={styles.pageContainer} style={{ backgroundColor: background, color: text }}>
      <div className={styles.header}>
        <div className={styles.tooltipContainer}>
          <button className={styles.modeToggle} onClick={toggleColorScheme} aria-label="Change color scheme">
            <Palette size={24} />
          </button>
          <span className={styles.tooltip}>Change theme (← / → arrow keys)</span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.fontSection}>
          <div className={styles.fontInfo}>
            <h2 className={styles.fontTitle}>
              <a href={getGoogleFontUrl(display)} target="_blank" rel="noopener noreferrer">
                {display}
              </a>
            </h2>
            <p className={styles.fontDescription}>{displayInfo}</p>
            <div className={styles.fontControl}>
              <Type size={16} />
              <input
                type="range"
                min="48"
                max="72"
                value={titleFontSize}
                onChange={handleTitleFontSizeChange}
                style={{ '--slider-color': slider }}
              />
              <span>{titleFontSize}px</span>
            </div>
          </div>
          <h1 
            className={`${styles.title} ${isChanging ? styles.changing : ''}`}
            style={{ 
              fontFamily: `'${display}', serif`,
              fontSize: `${titleFontSize}px`,
            }}
          >
            Type Pairs: Curated typography combinations for designers
          </h1>
        </div>
        <div className={styles.fontSection}>
          <div className={styles.fontInfo}>
            <h3 className={styles.fontSubtitle}>
              <a href={getGoogleFontUrl(body)} target="_blank" rel="noopener noreferrer">
                {body}
              </a>
            </h3>
            <p className={styles.fontDescription}>{bodyInfo}</p>
            <div className={styles.fontControl}>
              <Type size={16} />
              <input
                type="range"
                min="12"
                max="24"
                value={bodyFontSize}
                onChange={handleBodyFontSizeChange}
                style={{ '--slider-color': slider }}
              />
              <span>{bodyFontSize}px</span>
            </div>
          </div>
          <div 
            className={`${styles.body} ${isChanging ? styles.changing : ''}`}
            style={{ 
              fontFamily: `'${body}', sans-serif`,
              fontSize: `${bodyFontSize}px`,
            }}
          >
            <p>
              Type pairs are carefully selected combinations of fonts that work harmoniously together in a design. 
              Typically, a type pair consists of a display font for headings and a body font for paragraph text. 
              When chosen well, these pairs can enhance readability, establish visual hierarchy, and create a 
              cohesive aesthetic for your design project.
            </p>
            <p>
              To use this Type Pairs viewer effectively:
            </p>
            <ol>
              <li>Press the spacebar or click the "Change Type Pair" button to cycle through different font combinations.</li>
              <li>Observe how the heading and body text change with each pair.</li>
              <li>Use the sliders to adjust font sizes and see how it affects the overall look.</li>
              <li>Click on the font names to visit their Google Fonts pages for more information or to use them in your projects.</li>
              <li>Toggle between color schemes using the palette icon or arrow keys to see how the pairs look in different contexts.</li>
            </ol>
            <p>
              When choosing a type pair for your project, consider the mood you want to convey, the readability 
              of the body font, and how well the styles complement each other. A good pair will create contrast 
              while maintaining harmony, guiding the reader's eye and enhancing the overall user experience.
            </p>
            <div className={styles.footer} style={{ backgroundColor: background, color: text, border: `1px solid ${border}` }}>
              {isTabletOrMobile ? (
                <div className={styles.footerButtons}>
                  <button 
                    className={styles.changeFontButton} 
                    onClick={changeFont}
                    style={{ backgroundColor: button, color: background }}
                  >
                    Change Type Pair
                  </button>
                  <button 
                    className={styles.changeFontButton} 
                    onClick={toggleColorScheme}
                    style={{ backgroundColor: button, color: background }}
                  >
                    Change Color
                  </button>
                </div>
              ) : (
                <div className={styles.spacebarHint}>
                  Press spacebar to see a new pair or press left and right arrows to cycle through color schemes
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FontCombinationViewer;