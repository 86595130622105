
import React from 'react';
import ReactGA from 'react-ga4';
import FontCombinationViewer from './fontCombinationViewer';

// Initialize GA4
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-0RBJ2RDSSH');
}

function App() {
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // Send pageview with a custom path
      try {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
      } catch (error) {
        console.error('Failed to send pageview to Google Analytics', error);
      }
    }
  }, []);

  return (
    <div className="App">
      <FontCombinationViewer />
    </div>
  );
}

export default App;